import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { env } from '../../../environment'
import EvilDiv from '../generic/EvilDiv'
import Edit from './Edit'
import { Button, Collapse } from '@mui/material'
import { getHash } from '../../utils/Crypto'

export interface CmsBlockProps {
  blockId: string
  showButton?: string
}

export default function CmsBlock(props: CmsBlockProps) {
  const [expanded, setExpanded] = React.useState(props.showButton === undefined)
  const handleClick = () => {
    setExpanded(!expanded)
  }

  const data = useStaticQuery(graphql`
    query {
      allCockpitPublicBlock {
        nodes {
          cockpitId
          block_id {
            value
          }
          content {
            value {
              childMarkdownRemark {
                html
              }
            }
          }
          lang
        }
      }
    }
  `)

  const signature = getHash({ lang: env.getCmsLanguage(), material: props.blockId })

  return (
    <div>
      {data.allCockpitPublicBlock.nodes
        .filter((obj: any) => {
          return (
            obj.lang === env.getCmsLanguage() &&
            obj.block_id.value === props.blockId
          )
        })
        .map((obj: any, i) => (
          <div key={signature}>
            <Collapse
              in={expanded}
              key={'cms_block_' + props.blockId + '_collapse_' + i}
              timeout="auto"
              unmountOnExit
            >
              <EvilDiv
                content={obj.content.value.childMarkdownRemark.html}
                key={i}
              />
              <Edit cockpitId={obj.cockpitId} type="public_block" />
            </Collapse>
            {props.showButton && (
              <Button
                color="primary"
                key={'teaserbutton_' + i}
                onClick={handleClick}
              >
                {!expanded ? props.showButton : 'Ausblenden'}
              </Button>
            )}
          </div>
        ))}
    </div>
  )
}
